<template>
  <form @change="changes" v-if="datos">
      <div>
            <label>ID</label>
            <label>{{datos.ID}}</label>
      </div>
      <div>
            <label>Empresa</label>
            <v-select :options="listas.empresas" :reduce="option => option.code"  v-model="datos.EMPRESA"/>
      </div>
      <div>
            <label>Nombre</label>
            <input type="text" name="NOMBRE" v-model="datos.NOMBRE">
      </div>
      <div>
            <label>Cargo</label>
            <input type="text" name="CARGO" v-model="datos.CARGO">
      </div>
      <div>
            <label>E-mail</label>
            <input type="email" name="EMAIL" v-model="datos.EMAIL">
      </div>
      <div>
            <label>Móvil 1</label>
            <input type="tel" name="MOVIL1" v-model="datos.MOVIL1">
      </div>
      <div>
            <label>Móvil 2</label>
            <input type="tel" name="MOVIL2" v-model="datos.MOVIL2">
      </div>
  </form>
</template>

<script>
import {mapState,mapActions,mapMutations} from 'vuex'
export default {
    name:'DetalleContacto',
    data: function (){
        return {
            datos: null,
            id: this.$route.params.id,
            tabla: 'contactos'
        }
    },
    computed: {
        ...mapState(['tablas','listas','preferencias'])
    },
    methods:{
        ...mapActions(['api','cargartabla','cargarempresas']),
        changes(event,object){
            let campo = null
            let valor = null
            if(event!==null && event.target!==undefined){
                campo = event.target.name
                valor = event.target.value
            }else{
                campo = object[0]
                valor = object[1]
                if(valor){
                    this.tablas[this.tabla][this.cache.actuales[this.tabla]]['Z'+campo] = this.listas[campo.toLowerCase()].filter(function (e){
                        return e['code'] === valor
                    })[0]['label']
                }else{
                    this.tablas[this.tabla][this.cache.actuales[this.tabla]]['Z'+campo] = null
                }
            }
            let id = this.$route.params.id
            this.api([
                {'campo':'field','valor':campo},
                {'campo':'value','valor':valor},
                {'campo':'ID','valor':id},
                {'campo':'detalle','valor':this.tabla},
                'guardardetalle'
            ])
        }
    },
    async beforeMount(){
        if(!this.tablas[this.tabla]){
            await this.cargartabla([this.tabla,this.tabla])
        }
        await this.cargarempresas()
        const id = this.id
        this.datos = this.tablas[this.tabla].filter(
            function(e) {
                if(e['ID']){
                    return e['ID']===id
                }
            }
        )[0]
        if(!this.datos){
            this.$router.push('/'+this.tabla)
        }
    }
}
</script>

<style scoped>
form{
    text-align: left;
    display: grid;
    grid-template-columns: repeat(2,1fr);
    gap: 1.5%;
    margin: 2%;
}
label{
    display: block;
}
</style>